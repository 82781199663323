import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams  } from "react-router-dom";
import { SetupOrganizationProfile } from "../components/SetupOrganizationProfile";
import { PersonalInfo } from "../components/PersonalInfo";
import axios from "axios";

enum FormType {
  SetupOrganizationProfile,
  PersonalInformation,
}

const { REACT_APP_APPSUMO_HOST } =
  process.env;

export function OnboardingLayout() {
  const [currentForm, setCurrentForm] = useState(
    FormType.SetupOrganizationProfile
  );

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const getToken = async () => {
      let code = searchParams.get('code')

      await axios({
        method: "POST",
        url: `${REACT_APP_APPSUMO_HOST}/license`,
        headers: {
          "Content-type": "application/json",
        },
        data: {
          code: code,
        },
      });
    };

    getToken().then((response) => {
      console.log({ response })
    })

  }, []);

  const [formData, setFormData] = useState({
    website: "",
    company_name: "",
    company_size: "",
    app_sumo_code: "",
    name: "",
    last_name: "",
    email_address: "",
    country: "",
    role: "",
    timezone: "",
    subdomain: "",
    invoce_email_address: "",
    logo: "",
    working_options: "",
    unpaid_leave: "",
    paid_holiday: "",
    paid_seek_leave_per_year: "",
    remote_working_per_week: "",
  });

  const setCurrentFormData = (data: any) => {
    setFormData(data);
  };

  useEffect(() => {
    console.log(formData, "formData");
  }, [formData]);

  const renderForm = () => {
    switch (currentForm) {
      case FormType.SetupOrganizationProfile:
        return (
          <SetupOrganizationProfile
            setCurrentForm={setCurrentForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      case FormType.PersonalInformation:
        return (
          <PersonalInfo
            setCurrentFormProps={setCurrentForm}
            setCurrentFormData={setCurrentFormData}
            formData={formData}
          />
        );
      default:
        return null;
    }
  };

  return <div className="min-h-full min-w-full bg-white">{renderForm()}</div>;
}
